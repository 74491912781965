#contact{
    padding-top: 15%;
}

#contact .selected{
    color: #00a2ff;
}

#contact h2{
    text-align: center;
}

#contact h4{
    padding: 0;
    font-size: 1.5rem;
    text-align: center;
}
.graphics{
    height: 18rem;
}

.form-type-Category{
    padding: 7%;
    font-size: 2rem;
    text-align: left;
}

.form-category{
    
    border-radius: 5px;
    padding: 5.5% 15px;
    margin: 15px 0;
    text-align: left;
    box-shadow:0 0 5px 0 rgba(0,0,0,0.3 );
}

.form-category-selected{
    background-color:rgba(0, 162, 255, 0.05);
    border-radius: 5px;
    padding: 5.5% 15px;
    margin: 15px 0;
    text-align: left;
    box-shadow:0 0 5px 0 rgba(0,0,0,0.3 );
}
.form-category-selected:hover{
    background-color:rgba(0, 162, 255, 0.1);
    cursor: pointer;
}
.form-category:hover{
    background-color:rgba(0, 162, 255, 0.1);
    cursor: pointer;
}
.title{
    padding-bottom: 5%;
}