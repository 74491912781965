.contain{
    padding: 0 35px;
}

#home{
    /*background-image: url("../../Images/Home.jpg");
    background-repeat: no-repeat; 
    background-size: cover; 
    background: linear-gradient(90deg, #f7f7f7 50%, #fff2cc 50%);
    left: 0;
    right: 0;
    z-index: 6;
    box-shadow: inset 1px 0 15px 0 rgb(0,0,0,0.5);
    background-color: #f9f4ee;*/
    padding-bottom: 10%;

  }

.introduction-text{

  text-align: left;
}
.graphics2{
  padding-bottom: 5%;
}

.highlights{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: inline;
  color:#4b6e9a;
}
.about{
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    height: 200px;
    width: 730px;
    background-color: rgb(54, 128, 105, 0.5); 
    box-shadow: 2px 7px 13px 10px rgba(37, 91, 112, 0.3);
    border-radius: 15px;
    display: flex;
    transition: transform 3s;
  }
  
  .about:hover{
    cursor: pointer;
    background-color: rgb(54, 128, 105, 0.7); 
    transform: scale(1.1);
    transition: transform 3s ease-intransform-out;
  }
  
  .appointment{
    background-color: rgba(10, 80, 130, 0.5); 
    width: 200px;
    height: 90px;
    border: none;
    color: #f7f7f7;
    padding: 15px 32px;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50px;
    transition: transform 1s;
  }
  
  .appointment:hover{
  
    background-color:  rgb(10, 80, 130, 0.7);
    box-shadow: 2px 5px 5px 2px rgb(0, 0, 0,0.2);
    transform: scale(1.1);
    transition: transform 1s ease-intransform-out;
  }
  .appointment:focus {
    border: none;
    outline: none;
  }
  .appointment:active{
    box-shadow: 0px 0px 5px 5px rgb(0, 0, 0,0.2);
    background-color:   rgb(100, 100, 100, 0.5); 
  }
  
  .profileImage{
    width: 100%;
}
  
  @media only screen and (max-width: 993px) {
    .contain h2{
      margin-top: 15vh;
    }
  }