footer{
    padding: 4.7% 5%;
    background-color: #3d3d3d;
}
.footer-description{
    margin : 30px 0;
}

.footer-menu a{
    font-size: 0.90rem;
    color: #fff;
}
.footer-menu a:hover{
    color: #b8c1df;
}
#Footer-style p{
    font-size : 0.75rem;
    color : #f7f7f7;
}

#Footer-style h6{
    font-size : 1rem;
    color : #f7f7f7;
    margin : 15px 0;
}
.Social-Links-Icon{
    margin-right: 2.3%;
}