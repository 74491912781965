#testimonials{
    padding-top:10%;
    padding-bottom:2%;
  }

#testimonials h2{
    text-align: center;
    padding: 3%;
}

.cardflex{
    display: flex;
}
.testimonial-card{
    text-align: center;
}

.quote{
    height: 5rem;
}

.testimonialImage{
    border-radius: 100%;
    height: 100px;
}
.category-background{
    padding: 150px 0 50px;
    background-color: rgba(185, 185, 185, 0.9);
    box-shadow:0px 0px 13px 10px rgba(0,0,0,0.3 );
}
.testimonial-background{
    background-color: rgba(185, 185, 185, 0.9);
    padding: 20% 3% 10%;
}
.service-type{
    background-color: #8c8ca3;
}
.service-selected1{
    border-color: #1b1b2f;
    border-left: solid;
    border-left-width: 2px;
    border-right: solid;
    border-right-width: 1px;
}
.service-selected1:hover{
    background-color: #e7dfd5;
    cursor: pointer;
}
.service-selected2{
    border-color: #1b1b2f;
    border-left: solid;
    border-left-width: 1px;
    border-right: solid;
    border-right-width: 2px;
}
.service-selected2:hover{
    background-color: #e7dfd5;
    cursor: pointer;
}
.service-display{
    background-color: #e7dfd5;
    padding: 500px 0;
}
.service-illustration{
    padding: 0;
    margin: -15%;
}
.service-illustration2{
    padding: 0;
    margin: -3%;
}
.column-one{
    padding: 0 7%;
    margin-top: -80%;
    margin-bottom: 25%;
}
.stack{
    display: table-cell
}
.column-two{
    padding: 0 7%;
    margin-top: -50%;
}
#services a{
    text-decoration: none;
}
.links{
    text-align: center;
    margin: 50px 2%;
}
.link{
    padding: 35px 0;
    border-radius: 5px;
    opacity: 0.5;
    transition: 0.2s;
    background-color: gray;
}
.link:hover{
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    background-color: #323a56;
}
@media only screen and (max-width: 791px) {
    .column-one{
        margin-bottom: 50%;
    }
}