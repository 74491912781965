* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 5;
}
html {
  width:100%;
  scroll-behavior: smooth;
}
.App {
  background-color: #fcfcfc;
 /*  background-image: url("../../Images/background.png");
  background-attachment: fixed;
This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

/*Font type, size and color to be adjusted from here*/
.App h1,h2,h3,h4,h5{
  position: relative;
  font-family: 'Montserrat', sans-serif;
}
.App h1{
  color: #fcfcfc;
  font-size: 5rem;
  text-align: left;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #555555;
}

.App h2{
  font-size: 4rem;
  color: #b2b2b2;
  text-align: left;

}

.App h3{
  font-size: 2.3rem;
  color: #555555;
}

.App h4{
  font-size: 2.7rem;
  color: #555555;
  text-align: left;
}

.App h5{
  color: #555555;
  text-align: left;
}


.App p{
  font-size: 1.1rem;
  color: #555555;
  text-align: left;
  font-family: 'Raleway';
}

.home{
  /*background-image: url("../../Images/Home.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container 
  height: 100vh;
  background: linear-gradient(90deg, #f7f7f7 50%, #fff2cc 50%);
  left: 0;
  right: 0;

  position: absolute;
  z-index: 6;
  box-shadow: inset 1px 0 15px 0 rgb(0,0,0,0.5);*/
}


@media (max-width: 991px) {

  .App p{
    font-size: 1.5rem;
  }

  .App h1, .App h2{
    font-size:3rem;
  }

  .App #home, .App #history{
    padding: 25% 0 ;
  }
  .App .mission, .App #history, .App #contact {
    padding-left: 2%;
  }
}