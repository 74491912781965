
.navbar-light .navbar-toggler-icon{
  background-image: url('../../Images/Icons/grey_nobg.png') !important;
}
.navbar-light .navbar-toggler{
  border: 0;
  outline: none;
}

#Header a{
  color: #555555;
  text-decoration: none;
}

#Header a:hover{
  color: #576585;
  font-weight: bold;
}

#Header h5:hover{
  color: #576585;
  font-weight: bold;
}

#BlogHeader a{
  color: #555555;
  font-family: 'Raleway';
  font-size: 1.1rem;
}
#BlogHeader a:hover{
  color: #576585;
  font-weight: bold;
}

.header-container{
  padding: 0 5%;
  width: 100%;
  text-align: left;
  display: flex;
}

@media only screen and (max-width: 991px) {
  .nav-item a{
    font-size: 1.5rem !important;
  }


  .navbar-light .navbar-brand {
    display:none;
  }
}
